.P_main{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 15vh;
  padding-bottom: 10vh;
}
.main {
    width: 50%;
    height: 210vh;
    background: red;
    overflow: hidden;
    background: url("https://doc-08-2c-docs.googleusercontent.com/docs/securesc/68c90smiglihng9534mvqmq1946dmis5/fo0picsp1nhiucmc0l25s29respgpr4j/1631524275000/03522360960922298374/03522360960922298374/1Sx0jhdpEpnNIydS4rnN4kHSJtU1EyWka?e=view&authuser=0&nonce=gcrocepgbb17m&user=03522360960922298374&hash=tfhgbs86ka6divo3llbvp93mg4csvb38")
      no-repeat center/ cover;
    border-radius: 10px;
    box-shadow: 5px 20px 50px #000;
  }
  #chk {
    display: none;
  }
  .signup {
    position: relative;
    width: 100%;
    height: 100%;
  }
  label {
    color: #050505;
    font-size: 1.3em;
    justify-content: center;
    display: flex;
    margin: 60px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }
  input {
    width: 60%;
    height: 20px;
    background: #e0dede;
    justify-content: center;
    display: flex;
    margin: 20px auto;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
  }
  .btn_connect_candidat {
    width: 60%;
    height: 40px;
    margin: 10px auto;
    justify-content: center;
    display: block;
    color: #fff;
    background: #0b0a5c;
    font-size: 1em;
    font-weight: bold;
    margin-top: 20px;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: 0.5s ease-in;
    cursor: pointer;
  }
  button.btn_connect_candidat:hover {
    background: linear-gradient(217deg, #0b0a5c,rgba(255,0,0,0) 70.91%),
    linear-gradient(127deg,#b4b5b9, rgba(0,255,0,0) 70.71%),            
    linear-gradient(336deg,#0f1efa, rgba(0,0,255,0) 70.51%);
  }
  .login {
    height: 220vh;
    background: #eee;
    border-radius: 60% / 10%;
    transition: 0.8s ease-in-out;
    transform: translateY(-213vh);
  }
  .login label {
    color: #573b8a;
    transform: scale(0.6);
  }
  
  #chk:checked ~ .login {
    transform: translateY(-180px);
  }
  #chk:checked ~ .login label {
    transform: scale(1);
  }
  #chk:checked ~ .signup label {
    transform: scale(0.6);
  }
  .rdyo{
    display: flex;
    align-items: baseline;
    padding: 0px 63px;
    height: auto;
    justify-content: flex-end;
  }

  .rdyo input{
    margin-right: 1px;
  }
  .rdyo textarea{
    margin-right: 3px;
    margin-bottom: 2px;
    height: 15vh; 
    width: 83%;
  }
  #rpls{
    display: none;
  }