.job {
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 10px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: 40% 100%;
    background-position-x: right;
    color: black;
    background-color: #fff;
  }
  
  .job-title {
    margin-top: 0;
    text-align: left;
  }
  
  .job-details {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: left;
  }
  
  .label {
    font-weight: bold;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  ._button {
    
    background: #0b0a5c;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding: 1rem 3rem;
    transition: 1s;
    border-radius: 10px;

  }
  
  ._button:hover {
    letter-spacing: 0.2rem;
    padding: 1.1rem 3.1rem;
    background:linear-gradient(217deg, #0b0a5c,rgba(255,0,0,0) 70.91%),
    linear-gradient(127deg,#d3c6fc, rgba(0,255,0,0) 70.71%),            
    linear-gradient(336deg,#5a5a5a, rgba(0,0,255,0) 70.51%);
    transition: 1s;
    color:white;
    /* box-shadow: 0 0 35px var(--clr); */
    animation: box 3s infinite;
  }
  
  ._button::before {
    content: "";
 
    inset: 2px;
    background: #272822;
    border-radius: 10px;
  }
  
  ._button span {
   
    z-index: 1;
  }
  ._button i {
    
    inset: 0;
    display: block;
  }
  
  ._button i::before {
    content: "";
  
    width: 10px;
    height: 2px;
    left: 80%;
    top: -2px;
    border: 2px solid var(--clr);
    background: #03e9f4;
    transition: 0.2s;
  }
  
  ._button:hover i::before {
    width: 15px;
    left: 20%;
    animation: move 3s infinite;
  }
  
  ._button i::after {
    content: "";
    
    width: 10px;
    height: 2px;
    left: 20%;
    bottom: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
  }
  
  ._button:hover i::after {
    width: 15px;
    left: 80%;
    animation: move 3s infinite;
  }
  
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes box {
    0% {
      box-shadow: #27272c;
    }
    50% {
      box-shadow: 0 0 25px var(--clr);
    }
    100% {
      box-shadow: #27272c;
    }
  }
  