.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0b0a5c;
    color: #fff;
    padding: 10px;
    position: fixed;
    width: 100%;
    height: 4vh;
    margin-bottom: 9%;
    z-index: 1;
  }
  
  .logo {
    letter-spacing: 2px;
    text-decoration: none;
  } 
  .logo h3{
    color:  #ffffff;
    font: normal 36px 'Open Sans', cursive;
    margin: 0;
    }
  .logo h3 span{
      color:  lightseagreen;
  }
  
  .nav-links {
    display: flex;
    justify-content: space-around;
    list-style: none;
    width: 20%;
  }
  
  .nav-links li a {
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
  
  .nav-links li a:hover{
    border-bottom:1px solid #fff;
    transition: 500ms;
  }

  .burger {
    display: none;
  }
  
  @media screen and (max-width: 768px) {
    .nav-links {
      position: absolute;
      right: 0;
      height: 92vh;
      top: 8vh;
      background-color: #333;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      transform: translateX(100%);
      transition: transform 0.5s ease-in;
    }
    
    .nav-links li {
      opacity: 0;
    }
    
    .burger {
      display: block;
      cursor: pointer;
    }
    
    .line {
      width: 25px;
      height: 3px;
      background-color: #fff;
      margin: 5px;
    }
  }
  #conn{
    display :none;
  }
 