.parent {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .div1 { grid-area: 1 / 1 / 6 / 2;
    padding-top: 10vh; 
    padding-left: 1%;
    position: relative;
}
    .div2 { grid-area: 1 / 2 / 6 / 5;
    margin-top: 10vh;
   }
    .div3 { grid-area: 1 / 5 / 6 / 6;
      margin-top: 10vh;
      padding-left: 85%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: fixed;
     } 

    #banner {
        height: 85vh;
        min-height: 50vh;
        width: 100%;
        background: linear-gradient(217deg, #0b0a5c,rgba(255,0,0,0) 70.91%),
        linear-gradient(127deg,#b4b5b9, rgba(0,255,0,0) 70.71%),            
        linear-gradient(336deg,#0f1efa, rgba(0,0,255,0) 70.51%);
        overflow: hidden;
        border: solid 1px rgba(0, 0, 0, 0.2);
        margin-bottom: 0px;
      }
      #banner a {
        height: 100%;
        width: 100%;
        display: block;
        position: relative;
        overflow: hidden;
        animation: 1s fadein ease-in-out;
        animation-fill-mode: forwards;
      }
      #target {
        background: url(http://base.webdesignforyou.net/banner/target.png) center
          center no-repeat;
        background-size: 100% auto;
        margin: 0 auto;
        position: absolute;
        width: 500px;
        height: 500px;
        margin-top: -100px;
        margin-left: -100px;
        animation: 20s roty infinite linear;
      }
      #product {
        margin: 30px auto 0 auto;
        display: block;
        z-index: 10;
        width: 150px;
        position: relative;
        opacity: 0;
        animation: 1s growin ease-in-out 1s;
        animation-fill-mode: forwards;
      }
      #button {
        color: #fff;
        padding: 8px 15px 8px 15px;
        font-family: "Roboto Slab";
        font-weight: 700;
        font-size: 18px;
        background: #3ba7d1;
        display: inline-block;
        margin: 10px 0 0 0;
        border-bottom: 2px solid rgba(0, 0, 0, 0.15);
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        opacity: 0;
        animation: 1s fadein ease-in-out 2s;
        animation-fill-mode: forwards;
      }
      #sale {
        text-align: center;
        position: absolute;
        bottom: 20px;
        width: 100%;
        z-index: 10;
      }
      #sale-text {
        color: #fff;
        font-family: "Satisfy";
        font-size: 26px;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        opacity: 0;
        animation: 1s fadein ease-in-out 1.5s;
        animation-fill-mode: forwards;
      }
      #badge {
        box-sizing: border-box;
        color: #fff;
        padding: 10px 0 0 0;
        font-weight: 800;
        font-family: "Roboto Slab";
        font-size: 16px;
        text-align: center;
        width: 70px;
        height: 70px;
        background: #ff9900;
        border: solid 2px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        display: inline-block;
        border-radius: 50%;
        transform: rotate(345deg);
        animation: 1s fadein ease-in-out;
        -webkit-transition-delay: 6s;
        transition-delay: 6s;
        position: absolute;
        opacity: 0;
        transform: rotate(0deg);
        top: 0px;
        right: 0px;
        z-index: 10;
        animation: 1s rollin ease-in-out 2.5s;
        animation-fill-mode: forwards;
      }
      @keyframes fadein {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @keyframes roty {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      @keyframes rollin {
        0% {
          opacity: 0;
          transform: rotate(0deg);
          top: 0px;
          right: 0px;
        }
        100% {
          opacity: 1;
          transform: rotate(345deg);
          top: 40px;
          right: 40px;
        }
      }
      @keyframes growin {
        0% {
          opacity: 0;
          transform: scale(0.5);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
      
/*_______________________________________________________________________*/




