.parent_ {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .div1_ 
    {
      grid-area: 1 / 1 / 6 / 2;
      background-color: #0b0a5c;
      margin-top: 10vh;
      height: 90vh; 
      position: fixed;
      width: 20%;
      border-radius: 0 15px 0 0 ;
    }
    .div2_ {
      margin-top: 10vh;
      height: 100vh; 
      grid-area: 1 / 2 / 6 / 6; }

    /* css table liste admin / annonce */
  /* Table Styles */
  
  .table-wrapper {
      margin: 10px 70px 70px;
      box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
      margin-top: 10vh;
  }
  
  .fl-table {
      border-radius: 5px;
      font-size: 12px;
      font-weight: normal;
      border: none;
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      white-space: nowrap;
      background-color: white;
  }
  
  .fl-table td,
  .fl-table th {
      text-align: center;
      padding: 8px;
  }
  
  .fl-table td {
      border-right: 1px solid #f8f8f8;
      font-size: 12px;
  }
  
  .fl-table thead th {
      color: #ffffff;
      background: #0b0a5c;
  }
  
  .fl-table thead th:nth-child(odd) {
      color: #ffffff;
      background: #b4b5b9;
  }
  
  .fl-table tr:nth-child(even) {
      background: #f8f8f8;
  }
  
  /* Responsive */
  
  @media (max-width: 767px) {
      .fl-table {
          display: block;
          width: 100%;
      }
      .table-wrapper:before {
          content: "Scroll horizontally >";
          display: block;
          text-align: right;
          font-size: 11px;
          color: white;
          padding: 0 0 10px;
      }
      .fl-table thead,
      .fl-table tbody,
      .fl-table thead th {
          display: block;
      }
      .fl-table thead th:last-child {
          border-bottom: none;
      }
      .fl-table thead {
          float: left;
      }
      .fl-table tbody {
          width: auto;
          position: relative;
          overflow-x: auto;
      }
      .fl-table td,
      .fl-table th {
          padding: 20px 0.625em 0.625em 0.625em;
          height: 60px;
          vertical-align: middle;
          box-sizing: border-box;
          overflow-x: hidden;
          overflow-y: auto;
          width: 120px;
          font-size: 13px;
          text-overflow: ellipsis;
      }
      .fl-table thead th {
          text-align: left;
          border-bottom: 1px solid #f7f7f9;
      }
      .fl-table tbody tr {
          display: table-cell;
      }
      .fl-table tbody tr:nth-child(odd) {
          background: none;
      }
      .fl-table tr:nth-child(even) {
          background: transparent;
      }
      .fl-table tr td:nth-child(odd) {
          background: #f8f8f8;
          border-right: 1px solid #e6e4e4;
      }
      .fl-table tr td:nth-child(even) {
          border-right: 1px solid #e6e4e4;
      }
      .fl-table tbody td {
          display: block;
          text-align: center;
      }
  }
  
      /* nouvelle admin */

        
  .clearfix:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
  }
  .form_wrapper_{
    
    width: 100%;
    margin-top: 10vh;
    height: 100vh;
  }
  
  .form_wrapper {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 25px;
    margin: 2px;
    height: auto;
    padding-bottom: 6vh;
  }
  .form_wrapper h2 {
    font-size: 1.5em;
    line-height: 1.5em;
    margin: 0;
  }
  .form_wrapper .title_container {
    text-align: center;
    padding-bottom: 15px;
  }
  .form_wrapper h3 {
    font-size: 1.1em;
    font-weight: normal;
    line-height: 1.5em;
    margin: 0;
  }
  .form_wrapper label {
    font-size: 12px;
  }
  .form_wrapper .row {
    margin: 10px -15px;
  }
  .form_wrapper .row > div {
    padding: 0 15px;
    box-sizing: border-box;
  }
  .form_wrapper .col_half {
    width: 50%;
    float: left;
  }
  .form_wrapper .input_field {
    position: relative;
    margin-bottom: 20px;
    -webkit-animation: bounce 0.6s ease-out;
    animation: bounce 0.6s ease-out;
  }
  .form_wrapper .input_field > span {
    position: absolute;
    left: 0;
    top: 0;
    color: #333;
    height: 100%;
    border-right: 1px solid #cccccc;
    text-align: center;
    width: 30px;
  }

  .form_wrapper input[type=text], .form_wrapper input[type=email], .form_wrapper input[type=password] {
    width: 100%;
    padding: 8px 10px 9px 35px;
    height: 35px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    margin-top: 10px;
    outline: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .form_wrapper input[type=text]:hover, .form_wrapper input[type=email]:hover, .form_wrapper input[type=password]:hover {
    background: #fafafa;
  }
  .form_wrapper input[type=text]:focus, .form_wrapper input[type=email]:focus, .form_wrapper input[type=password]:focus {
    -webkit-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
    -moz-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
    box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
    border: 1px solid #f5ba1a;
    background: #fafafa;
  }
  .form_wrapper input[type=submit] {
    background: #f5ba1a;
    height: 35px;
    line-height: 35px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 1.1em;
    margin-bottom: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .form_wrapper input[type=submit]:hover {
    background: #e1a70a;
  }
  .form_wrapper input[type=submit]:focus {
    background: #e1a70a;
  }
  .form_wrapper input[type=checkbox], .form_wrapper input[type=radio] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .form_container .row .col_half.last {
    border-left: 1px solid #cccccc;
  }
  
  .checkbox_option label {
    margin-right: 1em;
    position: relative;
  }
  .checkbox_option label:before {
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    vertical-align: -2px;
    border: 2px solid #cccccc;
    padding: 0.12em;
    background-color: transparent;
    background-clip: content-box;
    transition: all 0.2s ease;
  }
  .checkbox_option label:after {
    border-right: 2px solid #000000;
    border-top: 2px solid #000000;
    content: "";
    height: 20px;
    left: 2px;
    position: absolute;
    top: 7px;
    transform: scaleX(-1) rotate(135deg);
    transform-origin: left top;
    width: 7px;
    display: none;
  }
  .checkbox_option input:hover + label:before {
    border-color: #000000;
  }
  .checkbox_option input:checked + label:before {
    border-color: #000000;
  }
  .checkbox_option input:checked + label:after {
    -moz-animation: check 0.8s ease 0s running;
    -webkit-animation: check 0.8s ease 0s running;
    animation: check 0.8s ease 0s running;
    display: block;
    width: 7px;
    height: 20px;
    border-color: #000000;
  }
  
  .radio_option label {
    margin-right: 1em;
  }
  .radio_option label:before {
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    border-radius: 100%;
    vertical-align: -3px;
    border: 2px solid #cccccc;
    padding: 0.15em;
    background-color: transparent;
    background-clip: content-box;
    transition: all 0.2s ease;
  }
  .radio_option input:hover + label:before {
    border-color: #000000;
  }
  .radio_option input:checked + label:before {
    background-color: #000000;
    border-color: #000000;
  }
  
  .select_option {
    position: relative;
    width: 100%;
  }
  .select_option select {
    display: inline-block;
    width: 100%;
    height: 35px;
    padding: 0px 15px;
    cursor: pointer;
    color: #7b7b7b;
    border: 1px solid #cccccc;
    border-radius: 0;
    background: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.2s ease;
  }
  .select_option select::-ms-expand {
    display: none;
  }
  .select_option select:hover, .select_option select:focus {
    color: #000000;
    background: #fafafa;
    border-color: #000000;
    outline: none;
  }
  
  .select_arrow {
    position: absolute;
    top: calc(50% - 4px);
    right: 15px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-width: 8px 5px 0 5px;
    border-style: solid;
    border-color: #7b7b7b transparent transparent transparent;
  }
  
  .select_option select:hover + .select_arrow, .select_option select:focus + .select_arrow {
    border-top-color: #000000;
  }
  
  .credit {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 15px;
    color: #f5ba1a;
  }
  .credit a {
    color: #e1a70a;
  }
  
  @-webkit-keyframes check {
    0% {
      height: 0;
      width: 0;
    }
    25% {
      height: 0;
      width: 7px;
    }
    50% {
      height: 20px;
      width: 7px;
    }
  }
  @keyframes check {
    0% {
      height: 0;
      width: 0;
    }
    25% {
      height: 0;
      width: 7px;
    }
    50% {
      height: 20px;
      width: 7px;
    }
  }
  @-webkit-keyframes expand {
    0% {
      -webkit-transform: scale3d(1, 0, 1);
      opacity: 0;
    }
    25% {
      -webkit-transform: scale3d(1, 1.2, 1);
    }
    50% {
      -webkit-transform: scale3d(1, 0.85, 1);
    }
    75% {
      -webkit-transform: scale3d(1, 1.05, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      opacity: 1;
    }
  }
  @keyframes expand {
    0% {
      -webkit-transform: scale3d(1, 0, 1);
      transform: scale3d(1, 0, 1);
      opacity: 0;
    }
    25% {
      -webkit-transform: scale3d(1, 1.2, 1);
      transform: scale3d(1, 1.2, 1);
    }
    50% {
      -webkit-transform: scale3d(1, 0.85, 1);
      transform: scale3d(1, 0.85, 1);
    }
    75% {
      -webkit-transform: scale3d(1, 1.05, 1);
      transform: scale3d(1, 1.05, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
      opacity: 1;
    }
  }
  @-webkit-keyframes bounce {
    0% {
      -webkit-transform: translate3d(0, -25px, 0);
      opacity: 0;
    }
    25% {
      -webkit-transform: translate3d(0, 10px, 0);
    }
    50% {
      -webkit-transform: translate3d(0, -6px, 0);
    }
    75% {
      -webkit-transform: translate3d(0, 2px, 0);
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes bounce {
    0% {
      -webkit-transform: translate3d(0, -25px, 0);
      transform: translate3d(0, -25px, 0);
      opacity: 0;
    }
    25% {
      -webkit-transform: translate3d(0, 10px, 0);
      transform: translate3d(0, 10px, 0);
    }
    50% {
      -webkit-transform: translate3d(0, -6px, 0);
      transform: translate3d(0, -6px, 0);
    }
    75% {
      -webkit-transform: translate3d(0, 2px, 0);
      transform: translate3d(0, 2px, 0);
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @media (max-width: 600px) {
    .form_wrapper .col_half {
      width: 100%;
      float: none;
    }
  
    .bottom_row .col_half {
      width: 50%;
      float: left;
    }
  
    .form_container .row .col_half.last {
      border-left: none;
    }
  
    .remember_me {
      padding-bottom: 20px;
    }
  }
  form:last-child{
    text-align: center;
  }
  .reg_btn{
    margin-top: 20px;
    position: absolute;
    width: auto;
    color: white;
    margin-left: 2%;
    transform: translate(-50%, -50%);
    background:#0b0a5c;
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    padding: 10px 25px;
    transition: 1s;
  }
  .reg_btn:hover {
    background: linear-gradient(217deg, #0b0a5c,rgba(255,0,0,0) 70.91%),
    linear-gradient(127deg,#b4b5b9, rgba(0,255,0,0) 70.71%),            
    linear-gradient(336deg,#0f1efa, rgba(0,0,255,0) 70.51%);
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #0f1efa, 0 0 25px #2b35c2, 0 0 50px #646efb,
      0 0 100px #03e9f4;
  }
  .reg_btn span {
    position: absolute;
    display: block;
  }
  /* nouvell annonce */

    .card {
    margin-left: 3%;
    width: 70%;
    padding: 30px 90px 90px 90px;
    border: 6px solid rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    position: relative;
    margin-top: 10vh;
  }
  .form-group label{
    display: block; 
    width: 100px; 
    margin: 10px;
  }
  .form-group input{
    display: block; 
    width: 100%; 
    height: 2vh;
    font-size: 20px;
  }
  .form-group textarea{
    display: block; 
    width: 100%; 
    height: 30vh;
    font-size: 15px;
  }
  
  /* .form-group_f input{
    display: block; 
    width: 50%; 
    height: 6vh;
    font-size: 20px;
    margin-top: 20px; 
    border-radius: 20px;
    transition: 1s;
  }
  .form-group_f input:hover{
    background: #03e9f4;
    color: #fff;
    box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
      0 0 100px #03e9f4;
      transition: 1000ms;
  } */
  
  .form-group_f button{
    display: block; 
    width: 50%; 
    height: 6vh;
    font-size: 20px;
    margin-top: 20px; 
    border-radius: 20px;
    transition: 1s;
  }
  .form-group_f button:hover{
    background: linear-gradient(217deg, #0b0a5c,rgba(255,0,0,0) 70.91%),
    linear-gradient(127deg,#b4b5b9, rgba(0,255,0,0) 70.71%),            
    linear-gradient(336deg,#0f1efa, rgba(0,0,255,0) 70.51%);
    color: #fff;
      transition: 1000ms;
  }
  .input_field {
    text-align: left;
  }
  .input_field label {
    font-size: 18px;
  }
  .div2_2{
    grid-area: 1 / 2 / 6 / 6;
    height: auto;
    margin-left: 3%;
    margin-right: 3%;
  }
  .signup.upd{
    height: auto;
    border: black solid 1px;
    margin-top: 10vh;
    border-radius: 15px;
  }
  .signup.upd button {width: 60%;
    height: 40px;
    margin: 10px auto;
    justify-content: center;
    display: block;
    background-color: #333;
    color: #fff;
    font-size: 1em;
    font-weight: bold;
    margin-top: 20px;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: 0.2s ease-in;
    cursor: pointer;}
    .upd_c{
      border: black solid 1px;
      border-radius: 15px;
      padding: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .div2_Profil_condidat{
      display: grid;
      grid-template-columns: repeat(11, 1fr);
      grid-template-rows: repeat(11, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px; 
    }
    .div1_pc { grid-area: 5 / 2 / 8 / 11; }
    .div2_pc { 

      grid-area: 9 / 2 / 12 / 11;
      margin-top: -5%;
    }
    .div3_pc { 
                grid-area: 1 / 4 / 4 / 11;
                background-repeat: no-repeat;
                padding-left: 10%;
    }
    .div4_pc { 
      grid-area: 1 / 2 / 4 / 4; 
      border-radius: 50%;
      background-position: center;
    } 
