.s_navbar{
    display: flex;
    padding: 0px;
}
.s_nav-links li {
    list-style: none;
    margin-top: 14px;
}
.s_nav-links li a {
    color: aliceblue;
    font-size: 20px;
    text-decoration: none;
}
.con_email{
    margin-bottom: 10vh;
    margin-right: 1%;
    color: aliceblue;
}
.s_nav-links .decon_email a{
    color: red;
}